import AllCompaniesPage from '@/pages/all-companies';
import { BrowserRouter, Route, Routes } from 'react-router';
import { CompanyDetailPage } from './pages/company-detail';

export const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route index element={<AllCompaniesPage />} />
        <Route path="company/:companyId" element={<CompanyDetailPage />} />
      </Routes>
    </BrowserRouter>
  );
};
