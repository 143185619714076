import { useUserSession } from '@reibus/frontend-utility';
import uploadClient from '@reibus/ingest-listings-api-client-axios';
import { useMemo } from 'react';

export const useListingsUploadClient = () => {
  const { data: userSession } = useUserSession();
  const listingsUploadClient = useMemo(() => {
    return uploadClient({
      environment: process.env.ENVIRONMENT,
      token: userSession?.identityToken,
    });
  }, [userSession?.identityToken]);

  return listingsUploadClient;
};
