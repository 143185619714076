import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { useAdminListings } from '@/context/admin-listings';

const Title = () => {
  const { companyName, filters } = useAdminListings();
  const { t } = useTranslation('listings-management');

  return (
    <Helmet>
      <title>
        {t('companyDetailHTMLTitleWithStatus', {
          name: companyName,
          lotStatus: filters?.lotStatus ?? 'ACTIVE',
        })}
      </title>
      <meta
        name="description"
        content={t('companyDetailDescriptionWithStatus', {
          lotStatus: filters?.lotStatus ?? 'ACTIVE',
        })}
      />
    </Helmet>
  );
};

export default Title;
