import { useEffect } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {
  type ISO31662Code,
  countryInfo,
  dateTimeFormatList,
  formatDateTime,
  generateLabelFromSubdivisionCode,
} from '@reibus/reibus-core-utils';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { OldLoader } from '@/components/Loader';

import { useAdminListings } from '@/context/admin-listings';

import CompanyListingDetailsTableRow from './Row';

import { useIsImperial } from '@/hooks/useIsImperial';
import type { CompanyLotDetails } from '@/types';
import type { DeploymentRegion } from '@reibus/reibus-core-utils/dist/src/functions/general/deploymentRegion';

const { dateTime } = dateTimeFormatList;
const tableHeaderKeys = [
  'tagNumber',
  'supplierTagNumber',
  'title',
  'location',
  'uploadedBy',
  'created',
  'updated',
  'lastUpdatedBy',
];

const useStyles = makeStyles()({
  companiesListingTable: {
    height: 'auto',
    overflow: 'auto',
    border: '1px solid #ddd',
    whiteSpace: 'nowrap',
    scrollbarWidth: 'thin', // firefox
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 8px #cacaca',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#bababa',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#bababa',
    },
  },
  tableHeaders: {
    height: 55,
    backgroundColor: '#ddd !important',
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#ddd !important',
    },
  },
  tableHeaderText: {
    color: 'rgba(13, 1, 10, 0.87)',
    fontWeight: 700,
  },
  noListings: {
    fontWeight: 700,
    textAlign: 'center',
  },
  checkboxRoot: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
});

const CompanyListingsDetailTable = () => {
  const {
    isLoading,
    isHeaderChecked,
    listings,
    rowsChecked,
    setIsDisabled,
    setIsHeaderChecked,
    setRowsChecked,
    setPage,
    filters,
    page,
    count,
  } = useAdminListings();

  const isImperial = useIsImperial();
  const { classes } = useStyles();
  const {
    t,
    i18n: { language: locale },
  } = useTranslation('listings-management');
  const { t: tEnums } = useTranslation('enums');
  const numberOfLots = listings?.length;
  const numberOfLotsChecked = rowsChecked?.length;

  useEffect(() => {
    numberOfLotsChecked ? setIsDisabled(false) : setIsDisabled(true);
  }, [rowsChecked]);

  const handleHeaderChecked = (checked: boolean) => {
    setIsHeaderChecked(checked);
    const allListingIDs = listings.map((lot) => lot.id);
    checked ? setRowsChecked(allListingIDs) : setRowsChecked([]);
  };

  const handleRowsChecked = (checked: boolean, id: string) => {
    const rowsCheckedCopy = [...rowsChecked];
    if (!rowsChecked.includes(id)) {
      rowsCheckedCopy.push(id);
    }
    if (!checked) {
      const rowIndex = rowsCheckedCopy.indexOf(id);
      rowsCheckedCopy.splice(rowIndex, 1);
    }
    setRowsChecked(rowsCheckedCopy);

    if (rowsCheckedCopy.length > 0 && rowsCheckedCopy.length < numberOfLots) {
      setIsHeaderChecked(false);
    }
    if (rowsCheckedCopy.length === numberOfLots) {
      setIsHeaderChecked(true);
    }
  };

  const listingsWithInformation = listings?.map((lot: CompanyLotDetails) => {
    const {
      dateUpdated,
      dateListed,
      location: {
        address: { country, countrySubdivision },
      },
    } = lot;
    return {
      ...lot,
      dateUpdated: new Date(dateUpdated),
      dateListed: new Date(dateListed),
      countryName: countryInfo
        .find((nation) => nation.shortLabel === country)
        ?.label?.toUpperCase(),
      state: countrySubdivision?.split('_')[1],
    };
  });

  return (
    <>
      {isLoading ? (
        <OldLoader />
      ) : (
        <TableContainer>
          <div className={classes.companiesListingTable}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  className={classes.tableHeaders}
                  id="individualCompanyTableColumns"
                >
                  <TableCell className={classes.tableHeaderText}>
                    <Checkbox
                      id="checkbox-header"
                      data-testid="checkAll"
                      classes={{ root: classes.checkboxRoot }}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      onChange={({ target: { checked } }) =>
                        handleHeaderChecked(checked)
                      }
                      checked={isHeaderChecked}
                    />
                  </TableCell>
                  {tableHeaderKeys.map((header) => {
                    return (
                      <TableCell
                        key={`company-detail-table-${header}`}
                        className={classes.tableHeaderText}
                        align="left"
                      >
                        {t(`individualCompanyTableColumns.${header}`)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listingsWithInformation?.map((listing, index) => {
                  const {
                    id,
                    tagNumber,
                    supplierTagNumber,
                    name,
                    metricName,
                    location: {
                      address: { country, countrySubdivision },
                    },
                    uploadedBy,
                    dateListed,
                    dateUpdated,
                    lastUpdatedBy,
                  } = listing;

                  const formattedUpdatedDate = formatDateTime(
                    dateUpdated,
                    locale,
                    dateTime,
                    (process.env.REGION as DeploymentRegion) ?? 'NA',
                  );
                  const formattedCreatedDate = formatDateTime(
                    dateListed,
                    locale,
                    dateTime,
                    (process.env.REGION as DeploymentRegion) ?? 'NA',
                  );

                  const stateAbbrWithDash = countrySubdivision
                    ? countrySubdivision.split('_').join('-')
                    : '';
                  const locationDisplay = countrySubdivision
                    ? generateLabelFromSubdivisionCode(
                        stateAbbrWithDash as ISO31662Code,
                      )
                    : tEnums(`Country.${country}.label`);

                  const listingTitle = isImperial ? name : metricName;

                  return (
                    <CompanyListingDetailsTableRow
                      key={id}
                      rowsChecked={rowsChecked}
                      handleRowsChecked={handleRowsChecked}
                      isHeaderChecked={isHeaderChecked}
                      id={id}
                      tagNumber={tagNumber}
                      supplierTagNumber={supplierTagNumber}
                      name={listingTitle}
                      location={locationDisplay}
                      uploadedBy={uploadedBy?.name}
                      dateListed={formattedCreatedDate}
                      dateUpdated={formattedUpdatedDate}
                      lastUpdatedBy={
                        lastUpdatedBy?.name ? lastUpdatedBy.name : t('platform')
                      }
                      numberOfLots={numberOfLots}
                      index={index + page * 25}
                      page={page}
                    />
                  );
                })}
              </TableBody>
            </Table>
            {numberOfLots === 0 ? (
              <div className={classes.noListings}>
                {t('noListingsForStatus', {
                  lotStatus: filters?.lotStatus ?? 'ACTIVE ',
                })}
              </div>
            ) : null}
          </div>
          {listings?.length === 0 ? null : (
            <TablePagination
              component="div"
              rowsPerPageOptions={[25]}
              count={count}
              rowsPerPage={25}
              page={page}
              onPageChange={(_, newPage) => {
                setPage(newPage);
              }}
            />
          )}
        </TableContainer>
      )}
    </>
  );
};

export default CompanyListingsDetailTable;
