import { useAdminListings } from '@/context/admin-listings';
import { useListingsSchemaWithBackendValidation } from '@/hooks/useListingsSchemaWithBackendValidation';
import { useListingsUploadClient } from '@/hooks/useListingsUploadClient';
import { type FlatfileRecord, recordHook } from '@flatfile/plugin-record-hook';
import { Sheet, Space, useListener } from '@flatfile/react';
import type { LotSchemaStringBased as LotSchema } from '@reibus/ingest-listings-api-client-axios';
import type { z } from 'zod';
import { COMPUTED_FIELDS, LISTINGS_UPLOAD_BLUEPRINT } from './blueprint';

type CellData = {
  value: unknown;
  messages: string[];
  valid: boolean;
  updatedAt: string;
};

type SheetData = {
  sheetId: string;
  workbookId: string;
  records: {
    id: string;
    values: Record<string, CellData>[];
  }[];
};

const SPACE_CONFIG = {
  metadata: {
    sidebarConfig: {
      showSidebar: true,
    },
    theme: {
      root: {
        primaryColor: '#2D6CCB',
      },
    },
  },
};

export const FlatfileSheet = () => {
  const listingsUploadClient = useListingsUploadClient();
  const { companyId } = useAdminListings();
  const listingsSchemaWithBackendValidation =
    useListingsSchemaWithBackendValidation();

  useListener(
    (client) => {
      client.use(
        recordHook(LISTINGS_UPLOAD_BLUEPRINT.slug, async (record) => {
          console.log(record.obj);
          const lot = await listingsSchemaWithBackendValidation.safeParseAsync(
            record.obj,
          );
          if (lot.success) {
            return record;
          }

          const errors = lot.error.issues
            .filter(
              (issue) =>
                issue.code !== 'invalid_type' || issue.received !== 'null',
            )
            .map((issue) => {
              return {
                field: issue.path.join('.'),
                message: issue.message,
              };
            });

          errors.forEach((error) => {
            record.addError(error.field, error.message);
          });
          return record;
        }),
      );
      client.use(
        recordHook(LISTINGS_UPLOAD_BLUEPRINT.slug, (record: FlatfileRecord) => {
          Object.entries(COMPUTED_FIELDS).forEach(([fieldName, transform]) =>
            record.compute(fieldName, transform),
          );
          return record;
        }),
      );
    },
    [listingsSchemaWithBackendValidation],
  );

  return (
    <Space config={SPACE_CONFIG}>
      <Sheet
        config={LISTINGS_UPLOAD_BLUEPRINT}
        onSubmit={async ({ data, sheet, job, event }) => {
          const allData: SheetData = await sheet.allData();
          const lots = allData.records.map(
            ({ values }) =>
              Object.fromEntries(
                Object.entries(values).map(([key, { value }]) => [
                  key,
                  value ?? null,
                ]),
              ) as unknown as z.infer<typeof LotSchema>,
          );

          console.log('Uploading lots', lots);

          await listingsUploadClient.upload({
            parameters: {
              companyId,
            },
            body: {
              type: 'string-based',
              lots,
            },
          });
        }}
      />
    </Space>
  );
};
