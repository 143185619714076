import { mixpanel } from '@/utils/mixpanel';
import { useEffect } from 'react';

export function usePageView(
  pageName: string,
  properties?: Record<string, never>,
  dependency = true,
) {
  useEffect(() => {
    if (dependency) {
      mixpanel.pageView(pageName, properties);
    }
  }, [dependency]);
}
