import { useQuery } from 'react-query';

import type { AllCompaniesActiveListingsPaginated } from '@/types';
import { axios } from '@/utils/axios';

type PaginationParameters = {
  page: number;
  pageSize: number;
  query: string | null;
};

export const useAllCompaniesActiveListings = ({
  page,
  pageSize,
  query,
}: PaginationParameters) => {
  return useQuery<AllCompaniesActiveListingsPaginated>({
    queryKey: ['listings', 'management', { page, pageSize, query }],
    queryFn: async () => {
      const { data: allCompaniesActiveListings } =
        await axios.get<AllCompaniesActiveListingsPaginated>(
          '/listings/management',
          {
            params: { page, pageSize, query: query || undefined },
          },
        );

      return allCompaniesActiveListings;
    },
  });
};
