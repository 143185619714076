import { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import OutsideClicker from 'react-outside-click-handler';
import { useNavigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import CustomSnackbar from 'components/Snackbar';

import { useAdminListings } from '@/context/admin-listings';
import {
  AVAILABLE_LOT_STATUSES,
  type LotStatus,
  businessDayRange,
} from '@/context/admin-listings/filters';
import type { CompanyListingsDetailsHeaderV2Props } from '@/types';
import { BooleanFilter } from './BooleanFilter';

import Button from '@/components/atoms/Button';
import { useFlatfile } from '@flatfile/react';
import { FlatfileSheet } from '../../flatfile';
import Menu from './Menu';

const useStyles = makeStyles()({
  backBtn: {
    position: 'relative',
    textDecoration: 'underline',
    fontWeight: 700,
    marginBottom: 24,
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
    border: 0,
    height: 40,
    paddingLeft: 30,
    letterSpacing: 0.5,
    color: '#2D6CCB',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 15,
      top: 0,
      bottom: 0,
      margin: 'auto',
      width: 0,
      height: 0,
      borderStyle: 'solid inset',
      borderWidth: '5px 9px 5px 0',
      borderColor: 'transparent #5c0a48 transparent transparent',
      transform: 'rotate(360deg)',
      WebkitTransform: 'rotate(360deg)',
    },
  },
  moreMenuContainer: {
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
  },
  isDisabled: {
    cursor: 'not-allowed',
  },
  tooltip: {
    backgroundColor: '#090A0D',
    fontSize: 12,
    top: '5px',
  },
  tooltipArrow: {
    color: '#090A0D',
  },
  shrinkTransition: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    marginLeft: '0 !important',
  },
  customInputLabel: {
    marginLeft: 30,
  },
  searchField: {
    marginBottom: 24,
  },
});

const DATE_LISTED_FILTERS: Record<string, () => [Date, Date]> = {
  '10_DAYS_OLD': () => businessDayRange(10),
  '20_DAYS_OLD': () => businessDayRange(20),
};

const CompanyListingsDetailHeader = ({
  setShrinkLabel,
  shrinkLabel,
}: CompanyListingsDetailsHeaderV2Props) => {
  const { openPortal } = useFlatfile();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const {
    companyName,
    isDisabled,
    isSnackbarOpen,
    snackbarMessage,
    setFilter,
    filters,
    handleSnackbarClose,
    setSearchValue,
    searchValue,
  } = useAdminListings();

  const { t } = useTranslation('listings-management');
  const { classes, cx } = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <h2>
          {t('companyDetailTitleWithStatus', {
            name: companyName,
            lotStatus: filters?.lotStatus ?? 'ACTIVE',
          })}
        </h2>
        <FormControl variant="standard">
          <InputLabel
            htmlFor="companySearchInput"
            shrink={false}
            className={
              shrinkLabel ? classes.shrinkTransition : classes.customInputLabel
            }
          >
            {t('search', { ns: 'my-accounts' })}
          </InputLabel>
          <Input
            className={classes.searchField}
            id="companyDetailSearchInput"
            defaultValue={''}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onFocus={() => {
              setShrinkLabel(true);
            }}
            onBlur={() => {
              searchValue === '' ? setShrinkLabel(false) : setShrinkLabel(true);
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon id="searchIcon" />
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid
        container
        item
        xs={12}
        spacing={6}
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={3}>
          <button
            type="button"
            className={classes.backBtn}
            onClick={() => {
              navigate('/');
            }}
          >
            {t('backToAllCompanies')}
          </button>
        </Grid>
        <Grid item xs={3}>
          <BooleanFilter
            buttons={[
              { label: t('10_DAYS_OLD'), value: '10_DAYS_OLD' },
              { label: t('20_DAYS_OLD'), value: '20_DAYS_OLD' },
            ]}
            onChange={(value: '10_DAYS_OLD' | '20_DAYS_OLD') => {
              setFilter({
                name: 'dateListed',
                value: value ? DATE_LISTED_FILTERS[value]() : undefined,
              });
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            variant="standard"
            value={filters.lotStatus ?? 'ACTIVE'}
            onChange={(event) => {
              setFilter({
                name: 'lotStatus',
                value: event.target.value as LotStatus,
              });
            }}
          >
            {AVAILABLE_LOT_STATUSES.map((status) => (
              <MenuItem value={status} key={status}>
                {t(`status.${status}`, { defaultValue: status })}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid
          container
          item
          xs={3}
          direction="row"
          sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <Grid item>
            <Button
              label={t('userListingsManagement.uploadListingsBtn')}
              variant="primary"
              sx={{ display: 'inline-block' }}
              onClick={() => {
                openPortal();
              }}
            />
            <FlatfileSheet />
          </Grid>
          <Grid>
            <div
              id="moreMenu"
              data-testid="moreVert"
              className={cx(classes.moreMenuContainer, {
                [classes.isDisabled]: isDisabled,
              })}
              onClick={
                !isDisabled
                  ? () => {
                      setShowMenu(!showMenu);
                    }
                  : undefined
              }
            >
              <Tooltip
                classes={{
                  tooltip: classes.tooltip,
                  arrow: classes.tooltipArrow,
                }}
                arrow
                disableHoverListener={!isDisabled}
                placement="top-end"
                title={`${t('selectAListing')}`}
              >
                <MoreVertIcon />
              </Tooltip>
              {showMenu && !isDisabled ? (
                <OutsideClicker
                  onOutsideClick={() => {
                    setShowMenu(false);
                  }}
                >
                  <Menu />
                </OutsideClicker>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={isSnackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Grid>
  );
};

export default CompanyListingsDetailHeader;
