import { type InputHTMLAttributes, useEffect, useState } from 'react';

import { useUserPreferences } from '@reibus/frontend-utility';
import { displayFormattingValue } from '@reibus/reibus-core-utils';

type IntlNumberInputProps = {
  value: string | number;
  precision?: number;
  disabled?: boolean;
  onChange?: (number: number) => void;
};

const IntlNumberInput = ({
  value,
  precision = 2,
  disabled = false,
  onChange,
  ...rest
}: IntlNumberInputProps & InputHTMLAttributes<HTMLInputElement>) => {
  const { preferredLocale = 'en-US' } = useUserPreferences();
  const [numberValue, setNumberValue] = useState<number>(0);

  const stringToNumber = (numberString: string) =>
    Number(numberString.replace(/[^0-9\\]/g, '')) / 10 ** precision;

  useEffect(() => {
    setNumberValue(typeof value === 'string' ? stringToNumber(value) : value);
  }, [value, numberValue]);

  const displayValue = displayFormattingValue(
    numberValue,
    {
      decimalPlaces: precision,
      trailingZeros: true,
    },
    undefined,
    preferredLocale,
  );

  return (
    <input
      value={displayValue}
      disabled={disabled}
      onChange={(e) => {
        e.preventDefault();
        if (onChange) {
          const finalValue = stringToNumber(e.target.value);
          setNumberValue(finalValue);
          onChange(finalValue);
        }
      }}
      {...rest}
    />
  );
};

export default IntlNumberInput;
