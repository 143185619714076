import { Decimal } from 'decimal.js';

import { isZeroPrice } from './calculatePrices';

interface ConversionFactors {
  [key: string]: Decimal;
}
type Unit = 'tonne' | 'mm' | 'lb' | 'ft';

const conversionFactors: ConversionFactors = {
  'tonne:lb': new Decimal('2204.62262185'),
  'mm:ft': new Decimal('0.003280839895013'),
  'lb:tonne': new Decimal('0.00045359237'),
  'ft:mm': new Decimal('304.800'),
};

const conversionKey = (from: Unit, to: Unit): string => `${from}:${to}`;

export const convertPrices = (
  from: Unit,
  to: Unit,
  value: string | null,
): string | null => {
  const key: string = conversionKey(from, to);
  if (!value || isZeroPrice(value)) {
    return null;
  }
  if (!(key in conversionFactors)) {
    throw new Error(`${from} -> ${to} not a registered conversion`);
  }

  const conversionFactor = new Decimal('1').dividedBy(conversionFactors[key]);

  return new Decimal(value).times(conversionFactor).toString();
};
