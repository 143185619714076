import { Tooltip, type TooltipProps } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { makeStyles } from 'tss-react/mui';

import { trackEvent } from 'utils/mixpanel';

import { useAdminListings } from '@/context/admin-listings';
import { companyQueryKey } from '@/hooks/companyQueryKey';
import type { RemoveLotsResponse, UpdateLotsResponse } from '@/types';
import { axios } from '@/utils/axios';

const useStyles = makeStyles()({
  moreMenu: {
    position: 'absolute',
    bottom: '0%',
    right: 25,
    boxShadow: '0px 4px 35px rgba(0, 0, 0, 0.2)',
    padding: '34px 40px',
    margin: 0,
    listStyle: 'none',
    backgroundColor: '#fff',
    zIndex: 999,
  },
  menuItem: {
    display: 'block',
    color: '#5d0549',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 10,
    minWidth: 175,
    cursor: 'pointer',
    textAlign: 'left',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&.disabled:hover': {
      textDecoration: 'none',
    },
    '&.disabled': {
      color: 'lightgrey',
    },
  },
  tooltip: {
    backgroundColor: '#090A0D',
    fontSize: 12,
    top: '5px',
  },
  tooltipArrow: {
    color: '#090A0D',
  },
});

const MAX_LISTINGS_TO_PRICE_UPDATE = 50;

type DisableableMenuItemProps = {
  text: string;
  disabled: boolean;
  disableReason: string;
};
const DisableableMenuItem = ({
  text,
  disabled,
  disableReason,
  onClick,
  ...tooltipProps
}: DisableableMenuItemProps & Partial<TooltipProps>) => {
  const { classes, cx } = useStyles();
  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      }}
      arrow
      disableHoverListener={!disabled}
      title={disableReason}
      placement="top-end"
      onClick={(e) => {
        e.preventDefault();
        if (disabled || !onClick) {
          return;
        }

        onClick(e);
      }}
      {...tooltipProps}
    >
      <span
        className={cx(classes.menuItem, {
          disabled,
        })}
      >
        {text}
      </span>
    </Tooltip>
  );
};

const Menu = () => {
  const { classes } = useStyles();

  const { t } = useTranslation('listings-management');

  const {
    companyId,
    setShowPriceTable,
    rowsChecked,
    setRowsChecked,
    setIsHeaderChecked,
    setIsSnackbarOpen,
    setIsLoading,
    setSnackbarMessage,
    filters,
  } = useAdminListings();

  const trackManageListingEvent = (
    type: 'begin-update-price' | 'remove' | 'mark-available',
  ) =>
    trackEvent('Manage Company Listings', {
      companyId,
      type,
      count: rowsChecked.length,
    });

  const listingActionsDisabled =
    !!filters.lotStatus && filters.lotStatus !== 'ACTIVE';
  const updatePricesOptionDisabled =
    rowsChecked.length > MAX_LISTINGS_TO_PRICE_UPDATE;

  const queryClient = useQueryClient();

  const updateLotsMutation = useMutation<UpdateLotsResponse>(
    async () => {
      const { data } = await axios.put<UpdateLotsResponse>(
        `/listings/management/company/${companyId}/listing/status`,
        {
          ids: rowsChecked,
        },
      );
      return data;
    },
    {
      onMutate: () => {
        setIsLoading(true);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['listings', 'management']);
        queryClient.invalidateQueries(companyQueryKey(companyId));

        setRowsChecked([]);
        setIsHeaderChecked(false);
        setIsSnackbarOpen(true);
        setSnackbarMessage(t('lotsUpdated', { count: data.updatedLots }));
      },
      onError: (error) => {
        Sentry.captureException(error);
        console.error(error);
      },
      onSettled: () => {
        setIsLoading(false);
      },
    },
  );

  const removeLotsMutation = useMutation<RemoveLotsResponse>(
    async () => {
      const body = {
        ids: rowsChecked,
        removalReason: 'NO_LONGER_AVAILABLE',
      };
      const { data } = await axios.delete<RemoveLotsResponse>(
        `/listings/management/company/${companyId}/listing`,
        { data: body },
      );

      return data;
    },
    {
      onMutate: () => {
        setIsLoading(true);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(['listings', 'management']);
        queryClient.invalidateQueries(companyQueryKey(companyId));

        setRowsChecked([]);
        setIsHeaderChecked(false);
        setIsSnackbarOpen(true);
        setSnackbarMessage(t('lotsRemoved', { count: data.lotsRemoved }));
      },
      onError: (error) => {
        Sentry.captureException(error);
        console.error(error);
      },
      onSettled: () => {
        setIsLoading(false);
      },
    },
  );

  return (
    <div className={classes.moreMenu}>
      <DisableableMenuItem
        text={t('removeListings')}
        disabled={listingActionsDisabled}
        onClick={() => {
          removeLotsMutation.mutate();
          trackManageListingEvent('remove');
        }}
        disableReason={t('actionLimitedToStatus', { lotStatus: 'ACTIVE' })}
      />
      <DisableableMenuItem
        text={t('markAvailable')}
        disabled={listingActionsDisabled}
        onClick={() => {
          updateLotsMutation.mutate();
          trackManageListingEvent('mark-available');
        }}
        disableReason={t('actionLimitedToStatus', { lotStatus: 'ACTIVE' })}
      />
      <DisableableMenuItem
        text={t('updatePrices')}
        disabled={updatePricesOptionDisabled || listingActionsDisabled}
        onClick={() => {
          setShowPriceTable(true);
          trackManageListingEvent('begin-update-price');
        }}
        disableReason={
          listingActionsDisabled
            ? t('actionLimitedToStatus', { lotStatus: 'ACTIVE' })
            : t('actionLimit', { maxListings: MAX_LISTINGS_TO_PRICE_UPDATE })
        }
      />
    </div>
  );
};

export default Menu;
