import { useEffect, useState } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, TableCell, TableRow } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import type { CompanyListingsRowProps } from '@/types';

const useStyles = makeStyles()({
  tableRow: {
    height: 55,
    '&:nth-of-type(2n)': {
      backgroundColor: '#eee !important',
    },
  },
  tableCellText: {
    lineHeight: '1.3125rem',
  },
  checkboxRoot: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
});

const CompanyListingDetailsTableRow = ({
  handleRowsChecked,
  rowsChecked,
  isHeaderChecked,
  id,
  tagNumber,
  supplierTagNumber,
  name,
  location,
  uploadedBy,
  dateListed,
  dateUpdated,
  lastUpdatedBy,
  numberOfLots,
  index,
  page,
}: CompanyListingsRowProps) => {
  const [isRowChecked, setIsRowChecked] = useState(false);
  const { classes } = useStyles();
  const rowFields = [
    tagNumber,
    supplierTagNumber,
    name,
    location,
    uploadedBy,
    dateListed,
    dateUpdated,
    lastUpdatedBy,
  ];

  useEffect(() => {
    if (
      (isHeaderChecked && rowsChecked.length === numberOfLots) ||
      (!isHeaderChecked && rowsChecked.length === 0)
    ) {
      setIsRowChecked(isHeaderChecked);
    }
  }, [isHeaderChecked]);

  useEffect(() => {
    if (rowsChecked?.includes(id)) {
      setIsRowChecked(true);
    }
  }, [page]);

  const handleRowChecked = (checked: boolean, id: string) => {
    handleRowsChecked(checked, id);
    setIsRowChecked(checked);
  };

  return (
    <TableRow
      className={classes.tableRow}
      key={`listingId-${id}`}
      id={`tagNumber-${tagNumber}`}
    >
      <TableCell className={classes.tableCellText}>
        <Checkbox
          id={`checkbox-${index}`}
          classes={{ root: classes.checkboxRoot }}
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          onChange={({ target: { checked } }) => handleRowChecked(checked, id)}
          checked={isRowChecked}
        />
      </TableCell>
      {rowFields.map((item, index) => {
        return (
          <TableCell
            key={`company-detail-rowCell-${index}`}
            className={classes.tableCellText}
            align="left"
          >
            {item}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default CompanyListingDetailsTableRow;
