import { useState } from 'react';

import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import type { Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  button: {
    color: theme.palette.primary.main,
    '&.Mui-selected': {
      color: theme.palette.grey[100],
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
}));

type Props = {
  buttons: { label: string; value: string }[];
  onChange: (val: string) => void;
};

export const BooleanFilter = ({ buttons, onChange }: Props) => {
  const [activeFilter, setActiveFilter] = useState('');
  const { classes } = useStyles();

  const handleChange = (_, value: string) => {
    setActiveFilter(value);
    onChange(value);
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={activeFilter}
      exclusive
      onChange={handleChange}
    >
      {buttons.map(({ label, value }) => {
        return (
          <ToggleButton
            className={classes.button}
            value={value}
            key={`${label}:${value}`}
          >
            <Typography variant="button">{label}</Typography>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
