import type { Flatfile } from '@flatfile/api';
import type {
  FlatfileRecord,
  TPrimitive,
  TRecordDataWithLinks,
} from '@flatfile/hooks';
import { JSONLotSchema } from './schema';

const SORT_ORDER = [
  'supplierTagNumber',
  'tagNumber',
  'supplierCompany',
  'dateReceived',
  'dateListed',
  'availabilityDate',
  'dateUpdated',
  'saleDate',
  'lotStatus',
  'lotActive',
  'material',
  'category',
  'format',
  'subcategory',
  'lotType',
  'dimensionThickness',
  'dimensionMin',
  'dimensionWidth',
  'dimensionLength',
  'weight',
  'materialGrade',
  'materialFinish',
  'materialCoating',
  'materialAlloy',
  'materialTemper',
  'materialCladding',
  'treatmentOptionAcrylic',
  'treatmentOptionChemTreated',
  'treatmentPickled',
  'treatmentOiled',
  'treatmentOptionOiled',
  'packageType',
  'packageQuantity',
  'unitType',
  'unitQuantity',
  'itemCount',
  'listingType',
  'acceptingBids',
  'unitAskPrice',
  'unitListPrice',
  'askPricePerLb',
  'pricePerLb',
  'itemCost',
  'lotNotes',
  'reibusNotes',
  'availabilityLeadTime',
  'locationName',
  'supplierAddress1',
  'supplierAddress2',
  'supplierCity',
  'supplierReportCity',
  'supplierRegion',
  'supplierPostalCode',
  'supplierCountry',
  'supplierName',
  'supplierEmail',
  'supplierPhone',
  'listingRep',
  'listingFee',
  'painted',
  'paintColor',
  'paintColorFamily',
  'paintVendor',
  'paintCode',
  'paintSystem',
  'paintType',
  'totalLength',
  'dimensionInsideDiameter',
  'dimensionOutsideDiameter',
  'coilOrientation',
  'originMill',
  'originCountry',
  'politicalEntity',
  'rockwellHardness',
  'yield',
  'tensile',
  'carbon',
  'manganese',
  'phosphorus',
  'sulfur',
  'silicon',
  'copper',
  'nickel',
  'chromium',
  'molybdenum',
  'tin',
  'aluminum',
  'vanadium',
  'niobium',
  'columbium',
  'nitrogen',
  'titanium',
  'boron',
  'calcium',
  'heatNumber',
  'millCert',
  'materialASTM',
  'saleRep',
  'buyer',
  'salePricePerLb',
  'deliveryPostalCode',
  'objectID',
  'currency',
  'visibility',
];

export const READ_ONLY = [
  'treatmentAcrylic',
  'treatmentChemTreated',
  'treatmentOiled',
];
export const COMPUTED_FIELDS = {
  treatmentAcrylic: (
    value: boolean,
    record: FlatfileRecord<TRecordDataWithLinks<TPrimitive>>,
  ) => record.get('treatmentOptionAcrylic') === 'ACRYLIC',
  treatmentChemTreated: (
    value: boolean,
    record: FlatfileRecord<TRecordDataWithLinks<TPrimitive>>,
  ) => record.get('treatmentOptionChemTreated') === 'CHEM_TREATED',
  treatmentOiled: (
    value: boolean,
    record: FlatfileRecord<TRecordDataWithLinks<TPrimitive>>,
  ) => record.get('treatmentOptionOiled') === 'OILED',
  supplierEmail: (
    value: string | undefined,
    record: FlatfileRecord<TRecordDataWithLinks<TPrimitive>>,
  ) => value?.toLowerCase(),
  listingRep: (
    value: string | undefined,
    record: FlatfileRecord<TRecordDataWithLinks<TPrimitive>>,
  ) => value?.toLowerCase(),
  saleRep: (
    value: string | undefined,
    record: FlatfileRecord<TRecordDataWithLinks<TPrimitive>>,
  ) => value?.toLowerCase(),
  visibility: (
    value: string | undefined,
    record: FlatfileRecord<TRecordDataWithLinks<TPrimitive>>,
  ) => {
    const listingSource = record.get('listingSource');
    return listingSource ? 'PRIVATE' : 'PUBLIC';
  },
};

const isComputedField = (fieldName: string) =>
  Object.keys(COMPUTED_FIELDS).includes(fieldName);

export const LISTINGS_UPLOAD_BLUEPRINT = generateBlueprint();

console.log({
  JSONLotSchema,
  LISTINGS_UPLOAD_BLUEPRINT,
});

export function generateBlueprint(): Flatfile.SheetConfig {
  const model = JSONLotSchema;
  const fields = generateFields(model);

  const notFound = new Set();
  fields.sort((lhs, rhs) => {
    const lhsIndex = SORT_ORDER.indexOf(lhs.key);
    const rhsIndex = SORT_ORDER.indexOf(rhs.key);

    if (lhsIndex !== -1 && rhsIndex !== -1) {
      return lhsIndex - rhsIndex;
    }

    if (lhsIndex !== -1 && rhsIndex === -1) {
      notFound.add(rhs.key);
      return -1;
    }

    if (lhsIndex === -1 && rhsIndex !== -1) {
      notFound.add(lhs.key);
      return 1;
    }

    notFound.add(lhs.key);
    notFound.add(rhs.key);
    return lhs.key.localeCompare(rhs.key);
  });

  notFound.forEach((key) =>
    console.warn(`Could not find key ${key} in SORT_ORDER`),
  );

  return {
    name: model.title ?? 'Listings Upload',
    slug: 'listings-upload',
    ...(model?.description && { description: model.description }),
    fields,
  };
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function generateFields(data: any): Flatfile.Property[] {
  if (!data || !data.properties) return [];

  const getOrigin = (url: string) => {
    try {
      return new URL(url).origin;
    } catch (error) {
      return '';
    }
  };
  const origin = getOrigin(data.$id);

  const fields = Object.keys(data.properties).map((key) => {
    const propertyType = getPropertyType(
      data,
      data.properties[key],
      key,
      data.required?.includes(key) || false,
      origin,
    );

    return propertyType;
  });

  return fields.flat().filter(Boolean);
}

function getPropertyType(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  schema: any,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  property: any,
  // biome-ignore lint/style/useDefaultParameterLast: <explanation>
  parentKey = '',
  // biome-ignore lint/style/useDefaultParameterLast: <explanation>
  isRequired = false,
  origin: string,
): Flatfile.Property[] {
  if (property.$ref) {
    const resolvedProperty = resolveReference(schema, property.$ref, origin);
    return getPropertyType(
      schema,
      resolvedProperty,
      parentKey,
      isRequired,
      origin,
    );
  }

  if (property.type === 'object' && property.properties) {
    const propertyFields = Object.keys(property.properties).map((key) => {
      return getPropertyType(
        property,
        property.properties[key],
        parentKey ? `${parentKey}_${key}` : key,
        property.required?.includes(key) || false,
        origin,
      );
    });

    return propertyFields.flat();
  }

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const extractEnumValues = (property: any) => {
    if (typeof property.type === 'string' && 'enum' in property) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return property.enum.map((value: any) => ({
        value,
        label: String(value),
      }));
    }

    if (Array.isArray(property.anyOf)) {
      return (
        property.anyOf.find((description) => 'enum' in description)?.enum ?? []
      );
    }

    return [];
  };

  const fieldTypes: Record<string, Flatfile.Property> = {
    string: { key: parentKey, type: 'string' },
    number: { key: parentKey, type: 'number' },
    integer: { key: parentKey, type: 'number' },
    boolean: {
      key: parentKey,
      type: 'boolean',
    },
    array: {
      key: parentKey,
      type: 'string-list',
    },
    enum: {
      key: parentKey,
      type: 'enum',
      config: property?.enum
        ? {
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            options: property.enum.map((value: any) => ({
              value,
              label: String(value),
            })),
          }
        : {
            options: [],
          },
    },
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const resolvePropertyType = (property: any) => {
    let resolvedType: Flatfile.Property;
    if (typeof property.type === 'string') {
      resolvedType =
        'enum' in property
          ? {
              ...fieldTypes.enum,
              type: 'enum',
              config: {
                options: extractEnumValues(property),
              },
            }
          : fieldTypes[property.type];
    } else if (Array.isArray(property.type)) {
      resolvedType = property.type
        .map((type) => fieldTypes[type])
        .find(Boolean);
    } else if (Array.isArray(property.anyOf)) {
      resolvedType = property.anyOf
        .map((description) => {
          return 'enum' in description
            ? {
                ...fieldTypes.enum,
                type: 'enum',
                config: {
                  options: extractEnumValues(description),
                },
              }
            : fieldTypes[description.type];
        })
        .find(Boolean);
    }

    if (!resolvedType) {
      console.error(
        `Could not resolve type for ${parentKey}, defaulting to string`,
      );
      console.dir(property);
      resolvedType = fieldTypes.string;
    }

    if (isComputedField(parentKey)) {
      resolvedType.constraints = [
        ...(resolvedType.constraints ?? []),
        { type: 'computed' },
      ];
    }

    if (resolvePropertyIsRequired(property)) {
      resolvedType.constraints = [
        ...(resolvedType.constraints ?? []),
        { type: 'required' },
      ];
    }

    resolvedType.readonly = READ_ONLY.includes(parentKey);

    return resolvedType;
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const resolvePropertyIsRequired = (property: any) => {
    if (Array.isArray(property.type)) {
      const resolvedIsRequired = !property.type.includes('null');
      return resolvedIsRequired;
    }

    if (Array.isArray(property.anyOf)) {
      const resolvedIsRequired = !property.anyOf.find(
        (description) => description.type === 'null',
      );
      return resolvedIsRequired;
    }

    return isRequired;
  };

  const resolvedType = resolvePropertyType(property);
  if (!resolvedType) {
    console.error(`Could not resolve type for ${parentKey}`);
    console.dir(property);
    return [];
  }

  console.log(`===> ${parentKey}`);
  console.dir(property);
  console.dir(resolvedType);

  const fieldConfig: Flatfile.Property = {
    label: parentKey,
    ...resolvedType,
    ...(property?.description && { description: property.description }),
  };

  return [fieldConfig];
}

function resolveReference(
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  schema: any,
  ref: string,
  origin: string,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
): Promise<any> {
  if (ref.startsWith('#/')) {
    return resolveLocalReference(schema, ref);
  }

  throw new Error('Can not resolve external references');
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function resolveLocalReference(schema: any, ref: string): any {
  const resolved = ref
    .split('/')
    .slice(1)
    .reduce(
      (acc, part) =>
        acc && (acc[part] || acc.$defs?.[part] || acc.definitions?.[part]),
      schema,
    );

  if (!resolved) throw new Error(`Cannot resolve reference: ${ref}`);
  return resolved;
}
