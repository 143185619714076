import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  shrinkTransition: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    marginLeft: '0 !important',
  },
  customInputLabel: {
    marginLeft: 30,
  },
  searchField: {
    marginBottom: 24,
  },
});

type Props = {
  handleSearchTextChange: (text: string) => void;
  setShrinkLabel: (shrinkLabel: boolean) => void;
  shrinkLabel: boolean;
  searchValue: string;
};

export const Header = ({
  handleSearchTextChange,
  setShrinkLabel,
  shrinkLabel,
  searchValue,
}: Props) => {
  const { t } = useTranslation('listings-management');
  const { classes } = useStyles();

  return (
    <Grid container>
      <Grid item lg={6} md={12}>
        <h2>{t('allCompaniesTitle')}</h2>
        <FormControl variant="standard">
          <InputLabel
            htmlFor="companySearchInput"
            shrink={false}
            className={
              shrinkLabel ? classes.shrinkTransition : classes.customInputLabel
            }
          >
            {t('search', { ns: 'my-accounts' })}
          </InputLabel>
          <Input
            className={classes.searchField}
            id="companySearchInput"
            defaultValue={''}
            onChange={(e) => {
              handleSearchTextChange(e.target.value);
            }}
            onFocus={() => {
              setShrinkLabel(true);
            }}
            onBlur={() => {
              searchValue === '' ? setShrinkLabel(false) : setShrinkLabel(true);
            }}
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon id="searchIcon" />
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
