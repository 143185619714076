import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import type { DeploymentRegion } from '@reibus/reibus-core-utils';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { makeStyles } from 'tss-react/mui';

import Button from '@/components/Button';
import CustomSnackbar from '@/components/Snackbar';
import { trackEvent } from 'utils/mixpanel';

import { useAdminListings } from '@/context/admin-listings';
import { companyQueryKey } from '@/hooks/companyQueryKey';
import type { ListingPrices, PriceUpdateHeaderV2Props } from '@/types';
import { axios } from '@/utils/axios';
import {
  checkHasValidLbLfPairAllListings,
  checkHasValidSameUnitPairAllListings,
  convertListingsPrices,
  convertPriceToImperial,
  convertZeroPricesToNull,
  revertPricesForBid,
  useGenerateErrorMessages,
} from '../../utils/validatePrices';

const useStyles = makeStyles()({
  backBtn: {
    position: 'relative',
    textDecoration: 'underline',
    fontWeight: 700,
    marginBottom: 24,
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
    border: 0,
    height: 40,
    paddingLeft: 30,
    letterSpacing: 0.5,
    color: '#5d0549',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 15,
      top: 0,
      bottom: 0,
      margin: 'auto',
      width: 0,
      height: 0,
      borderStyle: 'solid inset',
      borderWidth: '5px 9px 5px 0',
      borderColor: 'transparent #5c0a48 transparent transparent',
      transform: 'rotate(360deg)',
      '-webkit-transform': 'rotate(360deg)',
    },
  },
  submitBtn: {
    textAlign: 'right',
  },
  validationErrors: {
    padding: '20px 0px 10px 0px',
    color: 'red',
    fontSize: 12,
    textAlign: 'left',
  },
});

const PriceUpdateHeader = ({
  listingsPrices,
  newListingsPrices,
  setNewListingsPrices,
  setIsLoadingPricePage,
}: PriceUpdateHeaderV2Props) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successAndErrors, setSuccessAndErrors] = useState({
    fulfilled: 0,
    rejected: 0,
  });
  const {
    companyId,
    companyName,
    setShowPriceTable,
    snackbarMessage,
    handleSnackbarClose,
    isSnackbarOpen,
    setSnackbarMessage,
    setIsSnackbarOpen,
    setRowsChecked,
    setIsHeaderChecked,
  } = useAdminListings();

  const { t } = useTranslation('listings-management');
  const { classes } = useStyles();
  const isNA = process.env.REGION === 'NA';

  useEffect(() => {
    const { fulfilled, rejected } = successAndErrors;
    if (!fulfilled && !rejected) {
      return;
    }
    setIsSnackbarOpen(true);
    setSnackbarMessage(t('priceSubmittedMessage', { fulfilled, rejected }));
    setShowPriceTable(false);
  }, [successAndErrors]);

  const queryClient = useQueryClient();
  const updateListingPricesMutation = useMutation(
    async (newListingsPrices: ListingPrices[]) =>
      await Promise.allSettled(
        newListingsPrices.map(async (listing) => {
          try {
            setIsLoadingPricePage(true);
            const {
              id,
              askPricePerLb,
              askPricePerLinealFoot,
              pricePerLb,
              pricePerLinealFoot,
              currency,
              listingType,
            } = listing;
            await axios.put(
              `/listings/management/company/${companyId}/listing/${id}/price`,
              {
                askPricePerLb,
                askPricePerLinealFoot,
                pricePerLb,
                pricePerLinealFoot,
                currency,
                listingType,
              },
            );
          } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw error;
          }
        }),
      ),
    {
      onSuccess: (updatedPrices) => {
        queryClient.invalidateQueries(companyQueryKey(companyId));
        const successAndErrorCount = updatedPrices.reduce(
          (acc, listing) => {
            const { status } = listing;
            if (['fulfilled', 'rejected'].includes(status)) {
              acc[status]++;
            }
            return acc;
          },
          { fulfilled: 0, rejected: 0 },
        );

        setSuccessAndErrors(successAndErrorCount);
      },
      onMutate: () => {
        setRowsChecked([]);
        setIsHeaderChecked(false);
        setIsLoadingPricePage(false);
      },
    },
  );

  const useHandleSubmit = () => {
    // Check if both LB and LF fields are empty for each price group for BUYOUT => invalid
    const hasValidLbLfPairs =
      checkHasValidLbLfPairAllListings(newListingsPrices);
    const initialValidation = useGenerateErrorMessages(
      validationErrors,
      hasValidLbLfPairs,
      isNA ? 'bothLbAndLfFieldsEmpty' : 'bothTonneAndMmFieldsEmpty',
      t,
    );

    // Check if any askPrice/listPrice pairs are missing their same unit counterpart for BUYOUT => invalid
    const hasValidSameUnitPairs =
      checkHasValidSameUnitPairAllListings(newListingsPrices);
    const secondValidation = useGenerateErrorMessages(
      initialValidation,
      hasValidSameUnitPairs,
      'bothSameUnitFieldsEmpty',
      t,
    );

    const uniqueErrors = Array.from(new Set(secondValidation)) as string[];
    setValidationErrors(uniqueErrors);

    if (!hasValidLbLfPairs || !hasValidSameUnitPairs) {
      return;
    }

    const nullifiedListings = convertZeroPricesToNull(newListingsPrices);

    // Check if any lots were changed from BUYOUT to BID, and revert their prices back to the original prices (in Imperial).
    const revertedListingsPrices = revertPricesForBid(
      listingsPrices,
      nullifiedListings,
    );
    setNewListingsPrices(revertedListingsPrices);

    // Convert prices from metric to imperial for EU for BUYOUT only
    const convertedListingsPrices = convertListingsPrices(
      process.env.REGION as DeploymentRegion,
      revertedListingsPrices,
      convertPriceToImperial,
      true,
    );

    updateListingPricesMutation.mutate(convertedListingsPrices);
    trackEvent('Manage Company Listings', {
      companyId,
      type: 'complete-update-price',
      count: listingsPrices.length,
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <h2>{t('priceTableHeader.updatePrices', { name: companyName })}</h2>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <button
            type="button"
            className={classes.backBtn}
            onClick={() => {
              setShowPriceTable(false);
            }}
          >
            {t('priceTableHeader.backToCompanyListings')}
          </button>
        </Grid>
        <Grid item xs={6} className={classes.submitBtn}>
          <Button
            kind="primary"
            color="primary"
            type="submit"
            onClick={useHandleSubmit}
          >
            {t('submit')}
          </Button>
          <div className={classes.validationErrors}>
            {validationErrors.length ? (
              <>
                <h5>{t('priceTableHeader.correctErrors')}</h5>
                {validationErrors.map((error, i) => {
                  return <li key={i}>{t(`priceTableHeader.${error}`)}</li>;
                })}
              </>
            ) : null}
          </div>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={isSnackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </Grid>
  );
};

export default PriceUpdateHeader;
