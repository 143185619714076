import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const Title = () => {
  const { t } = useTranslation('listings-management');
  return (
    <Helmet>
      <title>{t('priceTableHTMLTitle')}</title>
      <meta name="description" content="Update prices for selected listings" />
    </Helmet>
  );
};

export default Title;
