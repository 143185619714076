import Decimal from 'decimal.js';

const DEFAULT_MARGIN = '9';

export const isZeroPrice = (price: string | null): boolean => {
  return Number(price) === 0;
};

// margin = (listPrice - askPrice) / listPrice
export const calculateMargin = (
  listPrice: string | null,
  askPrice: string | null,
): string => {
  if (
    isZeroPrice(listPrice) ||
    isZeroPrice(askPrice) ||
    !listPrice ||
    !askPrice
  ) {
    return DEFAULT_MARGIN;
  }
  const listPriceDecimal = new Decimal(listPrice);
  const marginPercentage = listPriceDecimal
    .minus(new Decimal(askPrice))
    .dividedBy(listPriceDecimal)
    .times(100)
    .valueOf();
  return marginPercentage.toString();
};

// listPrice = askPrice / (1 - margin)
export const calculateListPrice = (
  margin: string = DEFAULT_MARGIN,
  askPrice?: string | null,
): string | null => {
  if (isZeroPrice(askPrice) || !askPrice) {
    return null;
  }
  const base = new Decimal(1).minus(new Decimal(margin).dividedBy(100));
  const price = new Decimal(askPrice).dividedBy(base);
  return price.toString();
};
