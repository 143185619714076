import {
  AdminListingsProviderV2,
  useAdminListings,
} from '@/context/admin-listings';
import { useParams } from 'react-router';
import PriceUpdatePage from './listing-prices/v2';
import CompanyListingsDetailPage from './listings-display/v2';

const CompanyDetailPage = () => {
  const { showPriceTable } = useAdminListings();
  return showPriceTable ? <PriceUpdatePage /> : <CompanyListingsDetailPage />;
};

const CompanyDetailPageWrapper = () => {
  const { companyId } = useParams();
  return (
    <AdminListingsProviderV2 id={companyId}>
      <CompanyDetailPage />
    </AdminListingsProviderV2>
  );
};

export { CompanyDetailPageWrapper as CompanyDetailPage };
