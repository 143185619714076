import { useState } from 'react';

import NotFoundDash from '@/components/NotFound';

import { useIsListingsAdmin } from '@/hooks/useIsListingsAdmin';
import CompanyListingsDetailHeader from './Header';
import CompanyListingsDetailTable from './Table';
import Title from './Title';

const CompanyListingsDetailPage = () => {
  const [shrinkLabel, setShrinkLabel] = useState<boolean>(false);
  const isListingsAdmin = useIsListingsAdmin();

  return (
    <>
      {!isListingsAdmin ? (
        <NotFoundDash />
      ) : (
        <>
          <Title />
          <CompanyListingsDetailHeader
            setShrinkLabel={setShrinkLabel}
            shrinkLabel={shrinkLabel}
          />
          <CompanyListingsDetailTable />
        </>
      )}
    </>
  );
};

export default CompanyListingsDetailPage;
