import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { dateTimeFormatList, formatDateTime } from '@reibus/reibus-core-utils';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { OldLoader } from '@/components/Loader';

import { getLastUpdatedLot } from './getLastUpdatedLot';
import { getOldestListingAge } from './getOldestListingAge';

import { Row } from './Row';

import type { CompanyListings } from '@/types';
import type { AllCompaniesActiveListingsPaginated } from '@/types';
import type { DeploymentRegion } from '@reibus/reibus-core-utils/dist/src/functions/general/deploymentRegion';

const { dateTime } = dateTimeFormatList;
type Header = {
  id: string;
  align?: 'left' | 'right' | 'center';
};

const TABLE_HEADERS: Header[] = [
  { id: 'company' },
  { id: 'activeListings', align: 'right' },
  { id: 'oldestListing' },
  { id: 'lastUpdated' },
];

const useStyles = makeStyles()({
  companiesListingTable: {
    height: 'auto',
    overflow: 'auto',
    border: '1px solid #ddd',
    scrollbarWidth: 'thin', // firefox
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 8px #cacaca',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#bababa',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#bababa',
    },
  },
  tableHeaders: {
    height: 55,
    backgroundColor: '#ddd !important',
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#ddd !important',
    },
  },
  tableHeaderText: {
    color: 'rgba(13, 1, 10, 0.87)',
    fontWeight: 700,
  },
});

type Props = {
  allCompaniesPageResponse: AllCompaniesActiveListingsPaginated;
  isLoading: boolean;
  page: number;
  setPage: (page: number) => void;
};

export const ListingsTable = ({
  allCompaniesPageResponse,
  isLoading,
  page,
  setPage,
}: Props) => {
  const { classes } = useStyles();
  const {
    t,
    i18n: { language: locale },
  } = useTranslation('listings-management');

  const { allCompaniesActiveListings, totalResults } = allCompaniesPageResponse;
  return (
    <>
      {isLoading ? (
        <OldLoader />
      ) : (
        <TableContainer>
          <div className={classes.companiesListingTable}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  className={classes.tableHeaders}
                  id="allCompaniesColHeaders"
                >
                  {TABLE_HEADERS.map((header) => {
                    return (
                      <TableCell
                        className={classes.tableHeaderText}
                        key={header.id}
                        align={header.align || 'left'}
                      >
                        {t(`allCompaniesHeaders.${header.id}`)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {allCompaniesActiveListings?.map((company) => {
                  const { name, lots, id }: CompanyListings = company;
                  const lastUpdatedDate = lots.length
                    ? getLastUpdatedLot(lots)
                    : '-';
                  const formattedUpdateDate = formatDateTime(
                    new Date(lastUpdatedDate),
                    locale,
                    dateTime,
                    (process.env.REGION as DeploymentRegion) ?? 'NA',
                  );
                  const oldestListingAge = lots.length
                    ? getOldestListingAge(lots, locale)
                    : '-';
                  return (
                    <Row
                      key={id}
                      id={id}
                      name={name}
                      lots={lots}
                      oldestListingAge={oldestListingAge}
                      formattedUpdateDate={formattedUpdateDate}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </div>
          {totalResults === 0 ? null : (
            <TablePagination
              component="div"
              rowsPerPageOptions={[25]}
              count={totalResults}
              rowsPerPage={25}
              page={page}
              onPageChange={(_, newPage) => {
                setPage(newPage);
              }}
            />
          )}
        </TableContainer>
      )}
    </>
  );
};
