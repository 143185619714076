import { compareDesc, parseISO } from 'date-fns';

import type { Lot } from '@/types';

export const getLastUpdatedLot = (lots: Lot[]): string => {
  // date-fns compareDesc sorts the array of dates in order from most recent to oldest
  const [firstDate] = lots
    .map((lot) => parseISO(lot.dateUpdated))
    .sort(compareDesc);
  return firstDate?.toString();
};
