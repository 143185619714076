import axios from 'axios';
import { getUserSession } from '@reibus/frontend-utility';

const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'x-deployment-region': process.env.REGION ?? 'NA',
  },
});

axiosInstance.interceptors.request.use(async request => {
  const userSession = await getUserSession();
  request.headers.Authorization = userSession?.identityToken;
  return request;
});

export { axiosInstance as axios };
