import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { OldLoader } from 'components/Loader';

import PriceUpdateTableRow from './Row';

import type { PriceUpdateTableProps } from '@/types';

const tableHeaderKeysNa = [
  'listingType',
  'tagNumber',
  'supplierTagNumber',
  'askPricePerLb',
  'askPricePerLinealFoot',
  'margin',
  'pricePerLb',
  'pricePerLinealFoot',
];

const tableHeaderKeysEu = [
  'listingType',
  'tagNumber',
  'supplierTagNumber',
  'askPricePerTonne',
  'askPricePerMm',
  'margin',
  'pricePerTonne',
  'pricePerMm',
];

const useStyles = makeStyles()({
  companiesListingTable: {
    height: 'auto',
    overflow: 'auto',
    border: '1px solid #ddd',
    scrollbarWidth: 'thin', // firefox
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 8px #cacaca',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#bababa',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#bababa',
    },
  },
  tableHeaders: {
    height: 55,
    backgroundColor: '#ddd !important',
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#ddd !important',
    },
  },
  tableHeaderText: {
    color: 'rgba(13, 1, 10, 0.87)',
    fontWeight: 700,
    textAlign: 'center',
  },
});

const PriceUpdateTable = ({
  setNewListingsPrices,
  newListingsPrices,
  isLoadingPricePage,
}: PriceUpdateTableProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation('listings-management');
  const tableHeaderKeys =
    process.env.REGION === 'NA' ? tableHeaderKeysNa : tableHeaderKeysEu;

  return (
    <>
      {isLoadingPricePage ? (
        <OldLoader />
      ) : (
        <TableContainer>
          <div className={classes.companiesListingTable}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  className={classes.tableHeaders}
                  id="priceUpdateTableHeaders"
                >
                  {tableHeaderKeys.map((header) => {
                    return (
                      <TableCell
                        id={`price-table-header-${header}`}
                        key={`price-table-header-${header}`}
                        className={classes.tableHeaderText}
                      >
                        {t(`priceTable.${header}`)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {newListingsPrices.map((lot) => {
                  return (
                    <PriceUpdateTableRow
                      lot={lot}
                      key={lot.id}
                      newListingsPrices={newListingsPrices}
                      setNewListingsPrices={setNewListingsPrices}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      )}
    </>
  );
};

export default PriceUpdateTable;
