import { endOfDay, startOfDay, subBusinessDays } from 'date-fns';

export const AVAILABLE_LOT_STATUSES = [
  'ACTIVE',
  'SOLD',
  'SOLD_OFFSITE',
  'REMOVED',
  'REMOVED_BY_USER',
] as const;

export type LotStatus = (typeof AVAILABLE_LOT_STATUSES)[number];

export type AvailableFiltersV2 = {
  dateListed: [Date, Date];
  lotStatus: LotStatus;
  dateUpdated: [Date | null, Date | null];
  removalDate: [Date | null, Date | null];
};

export type AvailableFiltersV2Option<K extends keyof AvailableFiltersV2> = {
  name: K;
  value?: AvailableFiltersV2[K];
};

export const businessDayRange = (days: number): [Date, Date] => {
  const dateRangeStart = startOfDay(subBusinessDays(new Date(), days));
  const dateRangeEnd = endOfDay(new Date());
  return [dateRangeStart, dateRangeEnd];
};
