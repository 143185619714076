import { useEffect, useState } from 'react';

import type { DeploymentRegion } from '@reibus/reibus-core-utils';
import * as Sentry from '@sentry/react';
import { orderBy } from 'lodash';
import { useQuery } from 'react-query';

import NotFoundDash from '@/components/NotFound';

import { useAdminListings } from '@/context/admin-listings';
import { companyQueryKey } from '@/hooks/companyQueryKey';
import {
  convertListingsPrices,
  convertPriceToMetric,
} from '../../utils/validatePrices';

import PriceUpdateHeader from './Header';
import PriceUpdateTable from './Table';
import Title from './Title';

import { useIsListingsAdmin } from '@/hooks/useIsListingsAdmin';
import type { ListingPrices } from '@/types';
import { axios } from '@/utils/axios';

const PriceUpdatePage = () => {
  const [listingsPrices, setListingsPrices] = useState<ListingPrices[]>([]);
  const [newListingsPrices, setNewListingsPrices] = useState<ListingPrices[]>(
    [],
  );
  const [isLoadingPricePage, setIsLoadingPricePage] = useState(true);
  const { companyId, rowsChecked, listings } = useAdminListings();
  const isListingsAdmin = useIsListingsAdmin();

  useEffect(() => {
    if (process.env.REGION === 'NA') {
      setNewListingsPrices(JSON.parse(JSON.stringify(listingsPrices)));
    } else {
      const convertedListings = convertListingsPrices(
        process.env.REGION as DeploymentRegion,
        listingsPrices,
        convertPriceToMetric,
        false,
      );
      setNewListingsPrices(convertedListings);
    }
  }, [listingsPrices]);

  const getTagAndSupplierTagNumbers = (listingId: string) => {
    const listingById = listings.find(({ id }) => id === listingId);
    const tagAndSupplierTagNumbers = {
      tagNumber: listingById?.tagNumber,
      supplierTagNumber: listingById?.supplierTagNumber,
      id: listingId,
    };
    return tagAndSupplierTagNumbers;
  };

  const { data: pricesForSelectedListings = [] } = useQuery(
    [...companyQueryKey(companyId), 'price'],
    async () =>
      await Promise.allSettled(
        rowsChecked.map(async (listingId) => {
          try {
            const listingPriceData = await axios.get(
              `/listings/management/company/${companyId}/listing/${listingId}/price`,
            );
            const tagAndSupplierTags = getTagAndSupplierTagNumbers(listingId);
            return { ...listingPriceData.data, ...tagAndSupplierTags };
          } catch (error) {
            console.error(error);
            Sentry.captureException(error);
            throw error;
          } finally {
            setIsLoadingPricePage(false);
          }
        }),
      ),
  );

  useEffect(() => {
    const priceValues: ListingPrices[] = pricesForSelectedListings
      .filter(({ status }) => status === 'fulfilled')
      .map(({ value }: PromiseFulfilledResult<ListingPrices>) => value);

    const sortedListings = orderBy(priceValues, ['tagNumber'], ['asc', 'desc']);
    setListingsPrices(sortedListings);
  }, [pricesForSelectedListings]);

  return (
    <>
      {!isListingsAdmin ? (
        <NotFoundDash />
      ) : (
        <>
          <Title />
          <PriceUpdateHeader
            listingsPrices={listingsPrices}
            newListingsPrices={newListingsPrices}
            setNewListingsPrices={setNewListingsPrices}
            setIsLoadingPricePage={setIsLoadingPricePage}
          />
          <PriceUpdateTable
            isLoadingPricePage={isLoadingPricePage}
            setNewListingsPrices={setNewListingsPrices}
            newListingsPrices={newListingsPrices}
          />
        </>
      )}
    </>
  );
};

export default PriceUpdatePage;
