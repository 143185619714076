import { useUserSession } from '@reibus/frontend-utility';
import { LotSchemaStringBasedWithBackendValidation } from '@reibus/ingest-listings-api-client-axios';
import { useMemo } from 'react';

export const useListingsSchemaWithBackendValidation = () => {
  const { data: userSession } = useUserSession();
  const listingsSchemaWithBackendValidation = useMemo(() => {
    return LotSchemaStringBasedWithBackendValidation({
      environment: process.env.ENVIRONMENT,
      token: userSession?.identityToken,
    });
  }, [userSession?.identityToken]);

  return listingsSchemaWithBackendValidation;
};
