import * as React from 'react';

import MuiAlert, { type AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from 'tss-react/mui';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
});

const useStyles = makeStyles()((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

type SnackbarSeverity = 'success' | 'warning' | 'error' | 'info';

type SnackbarProps = {
  open: boolean;
  handleClose: (event: React.SyntheticEvent) => void;
  message: string;
  severity?: SnackbarSeverity;
};

const CustomSnackbar = ({
  open,
  handleClose,
  message,
  severity = 'success',
}: SnackbarProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomSnackbar;
