import { compareAsc, formatDistanceToNow, parseISO } from 'date-fns';
import { enGB, enUS } from 'date-fns/locale';

import type { Lot } from '@/types';

const localeMap = {
  'en-US': enUS,
  'en-GB': enGB,
};

export const getOldestListingAge = (lots: Lot[], locale: string): string => {
  const datesListed = lots.map((lot) => parseISO(lot.dateListed));
  // date-fns compareAsc sorts the array of dates in order from oldest to most recent
  datesListed.sort(compareAsc);

  const oldestListing = datesListed[0];
  const oldestListingAge = formatDistanceToNow(new Date(oldestListing), {
    locale: localeMap[locale],
  });

  return oldestListingAge;
};
