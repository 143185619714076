import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { usePageView } from '@/hooks/usePageView';
import NotFound from 'components/NotFound';

import { Header } from './Header';
import { ListingsTable } from './ListingsTable';

import { useAllCompaniesActiveListings } from '@/hooks/useAllCompaniesActiveListings';

import { useIsListingsAdmin } from '@/hooks/useIsListingsAdmin';

const AllCompaniesPage = () => {
  const [page, setPage] = useState(0);
  const pageSize = 25;

  const [searchValue, setSearchValue] = useState('');
  const [shrinkLabel, setShrinkLabel] = useState(false);
  const isListingsAdmin = useIsListingsAdmin();
  const { t } = useTranslation('listings-management');

  usePageView('Listings Management All Companies');

  const {
    data = {
      allCompaniesActiveListings: [],
      totalResults: 0,
      resultsRange: [page * pageSize, (page + 1) * pageSize],
    },
    isError,
    error,
    isLoading,
  } = useAllCompaniesActiveListings({ page, pageSize, query: searchValue });

  useEffect(() => {
    console.error(error);
    Sentry.captureException(error);
  }, [isError]);

  const handleSearchTextChange = (value: string) => {
    setShrinkLabel(true);
    setPage(0);
    setSearchValue(value);
  };

  return isListingsAdmin ? (
    <>
      <Helmet>
        <title>{t('allCompaniesHTMLTitle')}</title>
        <meta name="description" content={t('allCompaniesDescription')} />
      </Helmet>
      <Header
        handleSearchTextChange={handleSearchTextChange}
        setShrinkLabel={setShrinkLabel}
        shrinkLabel={shrinkLabel}
        searchValue={searchValue}
      />
      <ListingsTable
        allCompaniesPageResponse={data}
        isLoading={isLoading}
        page={page}
        setPage={setPage}
      />
    </>
  ) : (
    <NotFound />
  );
};

export default AllCompaniesPage;
