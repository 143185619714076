import { FlatfileProvider } from '@flatfile/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import {
  ldClient,
  queryClient,
  useUserPreferences,
} from '@reibus/frontend-utility';
import useLaunchDarklyIdentify from 'hooks/useLaunchDarklyIdentify';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { makeStyles } from 'tss-react/mui';
import { i18nSSR as i18n } from 'utils/i18n';
import { App } from './App';
import theme from './theme';

const useStyles = makeStyles()({
  background: {
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    backgroundColor: '#F6F7F7',
  },
  content: {
    width: 1440,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 48,
    paddingBottom: 48,
  },
});

const AppWithLayout = () => {
  const { classes } = useStyles();
  useLaunchDarklyIdentify();

  const { preferredLocale } = useUserPreferences();

  React.useEffect(() => {
    i18n.changeLanguage(preferredLocale);
  }, [preferredLocale]);

  return (
    <div className={classes.background}>
      <div className={classes.content}>
        <App />
      </div>
    </div>
  );
};

const Root = () => {
  React.useEffect(() => {
    document.body.style.overflowX = 'hidden';
  }, []);

  return (
    <>
      <Helmet>
        <title>Listings Management</title>
      </Helmet>
      <LDProvider
        clientSideID={process.env.LAUNCH_DARKLY_CLIENT_ID ?? ''}
        ldClient={ldClient}
      >
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline>
              <I18nextProvider i18n={i18n}>
                <FlatfileProvider
                  publishableKey={process.env.FLATFILE_PUBLISHABLE_KEY}
                >
                  <AppWithLayout />
                </FlatfileProvider>
              </I18nextProvider>
            </CssBaseline>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </LDProvider>
    </>
  );
};

export default Root;
