import type { DeploymentRegion } from '@reibus/reibus-core-utils';

import type { ListingPrices } from '@/types';

import { isZeroPrice } from './calculatePrices';
import { convertPrices } from './convertPrices';

import type { TFunction } from 'i18next';

// Between askPricePerLb and askPricePerLinealFoot, at least one must be filled out for BUYOUT.
// Between pricePerLb and pricePerLinealFoot, at least one must be filled out for BUYOUT.
export const checkHasValidLbLfPair = (
  priceLb: string | null,
  priceLF: string | null,
  listingType: string,
): boolean => {
  return (
    listingType !== 'BUYOUT' || !isZeroPrice(priceLb) || !isZeroPrice(priceLF)
  );
};

export const checkHasValidLbLfPairAllListings = (
  listings: ListingPrices[],
): boolean => {
  return listings.every(
    ({
      askPricePerLb,
      askPricePerLinealFoot,
      pricePerLb,
      pricePerLinealFoot,
      listingType,
    }) => {
      const isValidAskPrice = checkHasValidLbLfPair(
        askPricePerLb,
        askPricePerLinealFoot,
        listingType,
      );
      const isValidListPrice = checkHasValidLbLfPair(
        pricePerLb,
        pricePerLinealFoot,
        listingType,
      );
      return isValidAskPrice && isValidListPrice;
    },
  );
};

// Check if any askPrice/listPrice same unit pairs are missing their counterpart for BUYOUT => invalid
// i.e. If Ask Price per Lb is filled out but List Price per Lb is not or vice versa => invalid
export const checkHasValidSameUnitPair = (
  askPrice: string | null,
  listPrice: string | null,
  listingType: string,
): boolean => {
  return (
    listingType !== 'BUYOUT' || isZeroPrice(askPrice) === isZeroPrice(listPrice)
  );
};

export const checkHasValidSameUnitPairAllListings = (
  listings: ListingPrices[],
): boolean => {
  return listings.every(
    ({
      askPricePerLb,
      askPricePerLinealFoot,
      pricePerLb,
      pricePerLinealFoot,
      listingType,
    }) => {
      const isValidLB = checkHasValidSameUnitPair(
        askPricePerLb,
        pricePerLb,
        listingType,
      );
      const isValidLF = checkHasValidSameUnitPair(
        askPricePerLinealFoot,
        pricePerLinealFoot,
        listingType,
      );
      return isValidLB && isValidLF;
    },
  );
};

// The BE is expecting null, not 0 for prices.
const priceFields = [
  'askPricePerLb',
  'askPricePerLinealFoot',
  'pricePerLb',
  'pricePerLinealFoot',
];
export const convertZeroPricesToNull = (
  newListingsPrices: ListingPrices[],
): ListingPrices[] => {
  return newListingsPrices.map((newListing) => {
    return priceFields.reduce(
      (listing, priceField) => ({
        ...listing,
        [priceField]:
          listing[priceField] !== null && isZeroPrice(listing[priceField])
            ? null
            : listing[priceField],
      }),
      newListing,
    );
  });
};

// Check if any lots were changed from BUYOUT to BID, and revert their prices back to the original prices.
// -> This addresses the scenario where a user initally makes changes to the price as BUYOUT, but then ultimately changes listingType to BID.
export const revertPricesForBid = (
  listingsPrices: ListingPrices[],
  newListingsPrices: ListingPrices[],
) => {
  return newListingsPrices.map((newListing) => {
    if (newListing.listingType === 'BID') {
      const correspondingOriginalListing = listingsPrices.find(
        (oldListing) => oldListing.id === newListing.id,
      );
      if (correspondingOriginalListing === undefined) {
        return newListing;
      }

      const {
        askPricePerLb,
        askPricePerLinealFoot,
        pricePerLb,
        pricePerLinealFoot,
      } = correspondingOriginalListing;
      return {
        ...newListing,
        askPricePerLb,
        askPricePerLinealFoot,
        pricePerLb,
        pricePerLinealFoot,
      };
    }
    return newListing;
  });
};

export const convertPriceToImperial = (
  deploymentRegion: DeploymentRegion,
  price: string,
  priceField: string,
): string | null => {
  if (deploymentRegion === 'EU') {
    if (['askPricePerLb', 'pricePerLb'].includes(priceField)) {
      return convertPrices('tonne', 'lb', price);
    }
    if (['askPricePerLinealFoot', 'pricePerLinealFoot'].includes(priceField)) {
      return convertPrices('mm', 'ft', price);
    }
  }
  return price;
};

export const convertPriceToMetric = (
  deploymentRegion: DeploymentRegion,
  price: string,
  priceField: string,
): string | null => {
  if (deploymentRegion === 'EU') {
    if (['askPricePerLb', 'pricePerLb'].includes(priceField)) {
      return convertPrices('lb', 'tonne', price);
    }
    if (['askPricePerLinealFoot', 'pricePerLinealFoot'].includes(priceField)) {
      return convertPrices('ft', 'mm', price);
    }
  }
  return price;
};

export const convertListingsPrices = (
  deploymentRegion: DeploymentRegion,
  newListingsPrices: ListingPrices[],
  convertMeasurementSystem: (
    deploymentRegion: DeploymentRegion,
    price: string,
    priceField: string,
  ) => string | null,
  isSubmit?: boolean,
): ListingPrices[] => {
  return newListingsPrices.map((newListing) => {
    return priceFields.reduce(
      (listing, priceField) => ({
        ...listing,
        [priceField]:
          listing.listingType === 'BID' && isSubmit
            ? listing[priceField]
            : convertMeasurementSystem(
                deploymentRegion,
                listing[priceField],
                priceField,
              ),
      }),
      newListing,
    );
  });
};

export const useGenerateErrorMessages = (
  errorMessages: string[],
  isValid: boolean,
  errorKey: string,
  t: TFunction,
): string[] => {
  const validationErrors = [...errorMessages];
  if (!isValid) {
    validationErrors.push(t(errorKey));
  } else {
    const errorIndex = validationErrors.indexOf(t(errorKey));
    if (errorIndex >= 0) {
      validationErrors.splice(errorIndex, 1);
    }
  }
  return validationErrors;
};
