import type { ChangeEvent } from 'react';

import { MenuItem, TableCell, TableRow, TextField } from '@mui/material';
import { getPriceSymbol } from '@reibus/reibus-core-utils';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import IntlNumberInput from '@/components/IntlNumberInput';

import {
  calculateListPrice,
  calculateMargin,
  isZeroPrice,
} from '../../utils/calculatePrices';
import {
  checkHasValidLbLfPair,
  checkHasValidSameUnitPair,
} from '../../utils/validatePrices';

import type { ListingPrices, PriceUpdateTableRowProps } from '@/types';

const useStyles = makeStyles()({
  tableCell: {
    textAlign: 'center',
    padding: '8px 12px 8px 12px !important',
  },
  tableRows: {
    display: 'flex',
    justifyContent: 'center',
  },
  listingTypeField: {
    minWidth: 100,
    textAlign: 'center',
  },
  priceInputContainer: {
    width: '100%',
    display: 'flex',
    borderRadius: '0.125rem',
    border: '1px solid #e7e7e7',
  },
  invalid: {
    width: '100%',
    display: 'flex',
    borderRadius: '0.125rem',
    border: '1px solid red',
  },
  priceIndicator: {
    padding: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
    borderColor: '#e7e7e7',
    minWidth: '2.5rem',
  },
  priceInputField: {
    width: '100%',
    padding: '11px 11px',
    border: '1px solid #e7e7e7',
    fontSize: '1rem',
    borderStyle: 'none',
    borderWidth: 0,
  },
  priceInputFieldNegativeMargin: {
    width: '100%',
    padding: '11px 11px',
    border: '1px solid #e7e7e7',
    fontSize: '1rem',
    borderStyle: 'none',
    borderWidth: 0,
    backgroundColor: '#f75454',
  },
  placeholderPrice: {
    width: '100%',
    padding: '11px 11px',
    border: '1px solid #e7e7e7',
    fontSize: '1rem',
    color: '#ccc',
    borderStyle: 'none',
    borderWidth: 0,
  },
});

const listingTypes = [
  { value: 'BID', label: 'BID' },
  { value: 'BUYOUT', label: 'BUYOUT' },
];

const PriceUpdateTableRow = ({
  lot,
  newListingsPrices,
  setNewListingsPrices,
}: PriceUpdateTableRowProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation('listings-management');
  const {
    askPricePerLb,
    askPricePerLinealFoot,
    pricePerLb,
    pricePerLinealFoot,
    listingType,
    tagNumber,
    supplierTagNumber,
    id,
    currency,
  } = lot;
  const isDisabledForBid = listingType === 'BID';
  const hasValidAskPrice = checkHasValidLbLfPair(
    askPricePerLb,
    askPricePerLinealFoot,
    listingType,
  );
  const hasValidListPrice = checkHasValidLbLfPair(
    pricePerLb,
    pricePerLinealFoot,
    listingType,
  );
  const hasValidLbPrice = checkHasValidSameUnitPair(
    askPricePerLb,
    pricePerLb,
    listingType,
  );
  const hasValidLFPrice = checkHasValidSameUnitPair(
    askPricePerLinealFoot,
    pricePerLinealFoot,
    listingType,
  );
  const calculatedMargin =
    isZeroPrice(askPricePerLb) || isZeroPrice(pricePerLb)
      ? calculateMargin(pricePerLinealFoot, askPricePerLinealFoot)
      : calculateMargin(pricePerLb, askPricePerLb);
  const priceSymbol = currency === 'CAD' ? 'CA$' : getPriceSymbol(currency);
  const negativeMarginClass =
    Number(calculatedMargin) < 0 ? 'NegativeMargin' : '';

  const handleListingTypeChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    listingId: string,
  ) => {
    const newListingsPricesCopy = [...newListingsPrices];
    const indexOfListing = newListingsPricesCopy.findIndex(
      ({ id }) => id === listingId,
    );
    newListingsPricesCopy[indexOfListing].listingType = e.target.value;
    setNewListingsPrices(newListingsPricesCopy);
  };

  const handleListPriceChange = (
    listPrice: number,
    priceField: string,
    lot: ListingPrices,
  ) => {
    const {
      id: listingId,
      askPricePerLb,
      askPricePerLinealFoot,
      pricePerLb,
      pricePerLinealFoot,
    } = lot;
    const newListingsPricesCopy = [...newListingsPrices];
    const indexOfListing = newListingsPricesCopy.findIndex(
      ({ id }) => id === listingId,
    );
    const newListPrice = `${listPrice}`;
    newListingsPricesCopy[indexOfListing][priceField] = newListPrice;
    /*
      If pricePerLb is changed, margin will be recalculated based on that new list price.
      If pricePerLinealFoot is NOT null, then also need to recalculate pricePerLinealFoot based on new margin, 
      and vice versa.
    */
    if (
      priceField === 'pricePerLb' &&
      !isZeroPrice(pricePerLinealFoot) &&
      !isZeroPrice(newListPrice)
    ) {
      const newMarginLb = calculateMargin(newListPrice, askPricePerLb);
      const newListPricePerLF = calculateListPrice(
        newMarginLb,
        askPricePerLinealFoot,
      );
      newListingsPricesCopy[indexOfListing].pricePerLinealFoot =
        newListPricePerLF;
    } else if (
      priceField === 'pricePerLinealFoot' &&
      !isZeroPrice(pricePerLb) &&
      !isZeroPrice(newListPrice)
    ) {
      const newMarginLF = calculateMargin(newListPrice, askPricePerLinealFoot);
      const newListPricePerLb = calculateListPrice(newMarginLF, askPricePerLb);
      newListingsPricesCopy[indexOfListing].pricePerLb = newListPricePerLb;
    }
    setNewListingsPrices(newListingsPricesCopy);
  };

  const handleAskPriceChange = (
    askPrice: number,
    askPriceField: string,
    listPriceField: string,
    listingId: string,
    margin: string,
  ) => {
    const newListingsPricesCopy = [...newListingsPrices];
    const indexOfListing = newListingsPricesCopy.findIndex(
      ({ id }) => id === listingId,
    );
    const newAskPrice = `${askPrice}`;
    const newListPrice = calculateListPrice(margin, newAskPrice);
    newListingsPricesCopy[indexOfListing][askPriceField] = newAskPrice;
    newListingsPricesCopy[indexOfListing][listPriceField] = newListPrice;
    setNewListingsPrices(newListingsPricesCopy);
  };

  const handleMarginChange = (
    margin: number,
    askPriceLb: string | null,
    askPriceLF: string | null,
    listingId: string,
  ) => {
    const newListingsPricesCopy = [...newListingsPrices];
    const indexOfListing = newListingsPricesCopy.findIndex(
      ({ id }) => id === listingId,
    );
    const maxMargin = margin >= 100 ? '99.99' : `${margin}`;
    const newListPriceLb = askPriceLb
      ? calculateListPrice(maxMargin, askPriceLb)
      : null;
    const newListPriceLF = askPriceLF
      ? calculateListPrice(maxMargin, askPriceLF)
      : null;
    newListingsPricesCopy[indexOfListing].pricePerLb = newListPriceLb;
    newListingsPricesCopy[indexOfListing].pricePerLinealFoot = newListPriceLF;
    setNewListingsPrices(newListingsPricesCopy);
  };
  return (
    <TableRow key={`price-table-${id}`} id={`price-table-${id}`}>
      <TableCell className={classes.tableCell}>
        <TextField
          variant="standard"
          className={classes.listingTypeField}
          required
          select
          id="listingType-single-select"
          onChange={(e) => handleListingTypeChange(e, id)}
          value={listingType ?? 'BUYOUT'}
        >
          {listingTypes.map((option) => (
            <MenuItem
              id={`price-table-${option.value}`}
              key={option.value}
              value={option.value}
            >
              {t(`priceTable.${option.label}`)}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell className={classes.tableCell}>{tagNumber ?? '-'}</TableCell>
      <TableCell className={classes.tableCell}>
        {supplierTagNumber ?? '-'}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div
          className={
            hasValidAskPrice && hasValidLbPrice
              ? classes.priceInputContainer
              : classes.invalid
          }
        >
          <div className={classes.priceIndicator}>{priceSymbol}</div>
          <IntlNumberInput
            className={
              !askPricePerLb || isDisabledForBid
                ? classes.placeholderPrice
                : classes.priceInputField
            }
            disabled={isDisabledForBid}
            data-testid="askPricePerLb"
            name="askPricePerLb"
            onChange={(value) =>
              handleAskPriceChange(
                value,
                'askPricePerLb',
                'pricePerLb',
                id,
                calculatedMargin,
              )
            }
            value={Number(askPricePerLb) ?? ''}
          />
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div
          className={
            hasValidAskPrice && hasValidLFPrice
              ? classes.priceInputContainer
              : classes.invalid
          }
        >
          <div className={classes.priceIndicator}>{priceSymbol}</div>
          <IntlNumberInput
            className={
              !askPricePerLinealFoot || isDisabledForBid
                ? classes.placeholderPrice
                : classes.priceInputField
            }
            disabled={isDisabledForBid}
            name="askPricePerLinealFoot"
            onChange={(value) =>
              handleAskPriceChange(
                value,
                'askPricePerLinealFoot',
                'pricePerLinealFoot',
                id,
                calculatedMargin,
              )
            }
            value={Number(askPricePerLinealFoot) ?? ''}
          />
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div className={classes.priceInputContainer}>
          <div className={classes.priceIndicator}>%</div>
          <IntlNumberInput
            className={
              isDisabledForBid
                ? classes.placeholderPrice
                : classes[`priceInputField${negativeMarginClass}`]
            }
            data-testid="margin-input"
            disabled={isDisabledForBid}
            name="margin"
            onChange={(value) =>
              handleMarginChange(
                value,
                askPricePerLb,
                askPricePerLinealFoot,
                id,
              )
            }
            value={Number(calculatedMargin) ?? ''}
          />
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div
          className={
            hasValidListPrice && hasValidLbPrice
              ? classes.priceInputContainer
              : classes.invalid
          }
        >
          <div className={classes.priceIndicator}>{priceSymbol}</div>
          <IntlNumberInput
            className={
              !pricePerLb || isDisabledForBid
                ? classes.placeholderPrice
                : classes.priceInputField
            }
            disabled={isDisabledForBid}
            name="pricePerLb"
            precision={4}
            onChange={(value) =>
              handleListPriceChange(value, 'pricePerLb', lot)
            }
            value={Number(pricePerLb) ?? ''}
          />
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div
          className={
            hasValidListPrice && hasValidLFPrice
              ? classes.priceInputContainer
              : classes.invalid
          }
        >
          <div className={classes.priceIndicator}>{priceSymbol}</div>
          <IntlNumberInput
            className={
              !pricePerLinealFoot || isDisabledForBid
                ? classes.placeholderPrice
                : classes.priceInputField
            }
            disabled={isDisabledForBid}
            name="pricePerLinealFoot"
            precision={4}
            onChange={(value) =>
              handleListPriceChange(value, 'pricePerLinealFoot', lot)
            }
            value={Number(pricePerLinealFoot) ?? ''}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default PriceUpdateTableRow;
