import { createContext, useContext, useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';

import { useIndividualCompanyActiveListings } from '@/hooks/useIndividualCompanyActiveListings';

import type {
  AdminListingsContextV2Type,
  ListingsProviderProps,
} from '@/types';
import type { AvailableFiltersV2, AvailableFiltersV2Option } from './filters';

const AdminListingsContextV2 = createContext<AdminListingsContextV2Type>({
  listings: [],
  count: 0,
  companyName: '',
  companyId: '',
  searchValue: '',
  setSearchValue: () => {},
  setIsLoading: () => {},
  isLoading: true,
  isDisabled: false,
  isHeaderChecked: false,
  isSnackbarOpen: false,
  snackbarMessage: '',
  rowsChecked: [],
  filters: {},
  showPriceTable: false,
  setPage: () => {},
  page: 0,
  setFilter: () => {},
  setShowPriceTable: () => {},
  handleSnackbarClose: () => {},
  setIsDisabled: () => {},
  setIsHeaderChecked: () => {},
  setRowsChecked: () => {},
  setSnackbarMessage: () => {},
  setIsSnackbarOpen: () => {},
});

export const AdminListingsProviderV2 = ({
  id,
  children,
}: ListingsProviderProps) => {
  const [page, setPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isHeaderChecked, setIsHeaderChecked] = useState(false);
  const [rowsChecked, setRowsChecked] = useState<string[]>([]);
  const [showPriceTable, setShowPriceTable] = useState(false);
  const [filters, setFilters] = useState<Partial<AvailableFiltersV2>>({
    lotStatus: 'ACTIVE',
  });

  const paginationParameters = {
    page,
    pageSize: 25,
  };

  useEffect(() => {
    setPage(0);
  }, [filters, searchValue]);

  useEffect(() => {
    setRowsChecked([]);
    setIsHeaderChecked(false);
  }, [filters.lotStatus]);

  const {
    data: {
      individualCompanyActiveListings: { lots, name: companyName },
      totalResults: count,
    } = {
      individualCompanyActiveListings: { lots: [], name: '' },
      totalResults: 0,
    },
    isError,
    error,
    isLoading: fetchIsLoading,
  } = useIndividualCompanyActiveListings({
    companyId: id,
    paginationParameters,
    filters,
    query: searchValue,
  });

  useEffect(() => {
    setIsLoading(fetchIsLoading);
  }, [fetchIsLoading]);

  useEffect(() => {
    if (!isError) {
      return;
    }
    console.error(error);
    Sentry.captureException(error);
  }, [error, isError]);

  const setFilter = <K extends keyof AvailableFiltersV2>({
    name,
    value,
  }: AvailableFiltersV2Option<K>) => {
    setFilters({ ...filters, [name]: value });
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <AdminListingsContextV2.Provider
      value={{
        listings: lots,
        count,
        companyName,
        companyId: id,
        searchValue,
        setSearchValue,
        isLoading,
        setIsLoading,
        isDisabled,
        isHeaderChecked,
        isSnackbarOpen,
        snackbarMessage,
        rowsChecked,
        showPriceTable,
        setPage,
        page,
        filters,
        setFilter,
        setShowPriceTable,
        handleSnackbarClose,
        setIsDisabled,
        setIsHeaderChecked,
        setRowsChecked,
        setSnackbarMessage,
        setIsSnackbarOpen,
      }}
    >
      {children}
    </AdminListingsContextV2.Provider>
  );
};

export const useAdminListings = () => {
  return useContext(AdminListingsContextV2);
};
