import { useQuery } from 'react-query';

import type {
  AvailableFiltersV2,
  IndividualCompanyActiveListingsPaginated,
} from '@/types';
import { axios } from '@/utils/axios';
import { companyQueryKey } from './companyQueryKey';

type Args = {
  companyId: string;
  paginationParameters: {
    page: number;
    pageSize: number;
  };
  filters: Partial<AvailableFiltersV2>;
  query: string | null;
};

export const useIndividualCompanyActiveListings = ({
  companyId,
  paginationParameters,
  filters,
  query,
}: Args) => {
  return useQuery({
    queryKey: [
      ...companyQueryKey(companyId),
      { ...paginationParameters, ...filters, query },
    ],
    queryFn: async () => {
      const params = {
        ...Object.fromEntries(
          Object.entries(filters)
            .filter(([, value]) => value !== undefined)
            .map(([key, value]) => [
              key,
              typeof value === 'object' ? JSON.stringify(value) : value,
            ]),
        ),
        ...paginationParameters,
        query: query || undefined,
      };
      const { data: companyActiveListings } =
        await axios.get<IndividualCompanyActiveListingsPaginated>(
          `/listings/management/company/${companyId}/listing`,
          {
            params,
          },
        );

      return companyActiveListings;
    },
  });
};
